import { createInstance, Namespace, TFunction } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { SUPPORTED_LANGS } from '../constants';

/** This will work only for publicapp in the app rotuer */
const initI18next = async (lng: string, ns: string[], localePath: string) => {
  const i18nInstance = createInstance();

  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(
            `public/static/locales/${language}/${namespace}.json`
            // @TODO: Let's check this later
            // If here is not the exact string "public/static/locales/" it's not working (I think a limitation of the package: https://github.com/i18next/i18next-resources-to-backend)
            // 1.`${localePath}/${language}/${namespace}.json` - not working
            // 2. not working(not even loading `public/static/locales/${language}/${namespace}.json` that is the same as above :D)
            // namespace === 'base'
            //   ? `../../packages/shared-components/locales/${language}/base.json`
            //   : `public/static/locales/${language}/${namespace}.json`
            // 3. not working localePath(language, namespace)
          ),
      ),
    )
    .init({
      supportedLngs: SUPPORTED_LANGS,
      fallbackLng: 'fr',
      lng,
      fallbackNS: ns[0],
      defaultNS: ns[0],
      preload: SUPPORTED_LANGS,
      ns,
    });
  return i18nInstance;
};

export type { TFunction };

export async function getTranslationInstance(lng: string, ns: string[], localePath: string) {
  const i18nextInstance = await initI18next(lng, ns, localePath);
  return {
    t: i18nextInstance.getFixedT(lng, (Array.isArray(ns) ? ns[0] : ns) as Namespace),
    i18n: i18nextInstance,
    resources: i18nextInstance.services.resourceStore.data,
  };
}

export * from './Trans';
