import {
  experienceLevelDomains,
  experienceLevelExcludedPathnames,
  LOCALHOST,
  nonRelevantPathnames,
  positiveReviewDomains,
  positiveReviewPathnames,
  PROD_UK_TLD,
  reduceDisplayInformationDomains,
  reduceDisplayInformationExcludedPathnames,
  STAGING_GTLD,
} from './src/config/public-app-pathnames';
import { FeatureToggleConfig } from './src/types';

export const config = {
  // Here as a default / example
  'my-feature-flag': {
    defaultValue: false,
    variants: ['control', 'variation-1', 'variation-2'],
    experiment: {
      key: 'test-experiment',
      domains: [STAGING_GTLD, LOCALHOST],
      pathnames: ['**'],
    },
  },
  'positive-review': {
    defaultValue: false,
    variants: ['control', 'variation-review'],
    experiment: {
      key: 'positive-review',
      domains: positiveReviewDomains,
      pathnames: positiveReviewPathnames,
    },
  },
  'experience-level': {
    defaultValue: false,
    variants: ['control', 'variation-donut', 'variation-bar'],
    experiment: {
      key: 'experience-level',
      domains: experienceLevelDomains,
      excludedPathnames: experienceLevelExcludedPathnames,
    },
  },
  'reduce-display-information': {
    defaultValue: false,
    variants: ['control', 'variation-removal'],
    experiment: {
      key: 'reduce-display-information',
      domains: reduceDisplayInformationDomains,
      excludedPathnames: reduceDisplayInformationExcludedPathnames,
    },
  },
  calcom_scheduling: {
    defaultValue: false,
    enabledForEnvironments: ['development'],
  },
  'briefing-ai': {
    defaultValue: false,
    variants: ['control', 'variation-ai'],
    experiment: {
      key: 'experience-level',
      domains: [LOCALHOST, PROD_UK_TLD, STAGING_GTLD], // if you want on all domains then **
      excludedPathnames: nonRelevantPathnames, // We want to have the experiment on all relevant pathnames
    },
  },
} as const satisfies Record<string, FeatureToggleConfig>;
