import {
  // eslint-disable-next-line no-restricted-imports
  getServerSideTranslations as _getServerSideTranslations,
  initSSRTranslations,
  SSRConfig,
  useTranslation,
} from '@sortlist-frontend/translation/ssr';
import { isArray } from '@sortlist-frontend/utils';

import { getResourcePath, namespaces } from './server-only';

export type Namespace = Exclude<Parameters<typeof useTranslation>[0], undefined>;

export const translationsConfig = initSSRTranslations(getResourcePath, [...namespaces, 'base'] as string[]);

export const getServerSideTranslations = async (locale: string, namespacesRequired?: Namespace): Promise<SSRConfig> => {
  // Slice needed here cause serverSlideTranslations does not accept Readonly type
  return _getServerSideTranslations(
    locale,
    isArray(namespacesRequired)
      ? ([...(namespacesRequired?.slice() ?? []), 'base'] as string[])
      : ([namespacesRequired ?? 'base'] as string[]),
    translationsConfig,
  );
};
