import { Domain } from '../types';

// Pathnames that are not relevant for any experiment
export const nonRelevantPathnames = ['/robots.txt', '/sitemaps', '/_monitor', '/blog', '/sitemaps'].map(
  (pathname) => `${pathname}*/**`,
);
export const homepagePathnames = ['/', '/fr', '/de', '/nl', '/es'];
export const pagesPathnames = [
  '/about',
  '/agency',
  '/briefing-ai',
  '/briefing-edit-embed',
  '/briefing-embed',
  '/claim',
  '/clients',
  '/comparator-embed',
  '/contact',
  '/explore',
  '/feedback',
  '/how-it-works',
  '/impressum',
  '/join',
  '/meet',
  '/privacy',
  '/providers',
  '/project',
  '/referral',
  '/search',
  '/select-account',
  '/share',
  '/stories',
  '/story',
  '/terms',
  '/trouver-ma-prochaine-agence',
].map((pathname) => `${pathname}*/**`);

export const excludedPathnamesForDirectoryExperiments = [
  ...nonRelevantPathnames,
  ...homepagePathnames,
  ...pagesPathnames,
];
export const LOCALHOST = 'localhost';

export const STAGING_GTLD: Domain = 'www.sortlist-test.com';
export const STAGING_FR_TLD: Domain = 'www-fr.sortlist-test.com';
export const STAGING_ES_TLD: Domain = 'www-es.sortlist-test.com';
export const STAGING_NL_TLD: Domain = 'www-nl.sortlist-test.com';
export const STAGING_DE_TLD: Domain = 'www-de.sortlist-test.com';
export const STAGING_IT_TLD: Domain = 'www-it.sortlist-test.com';
export const STAGING_CH_TLD: Domain = 'www-ch.sortlist-test.com';
export const STAGING_CA_TLD: Domain = 'www-ca.sortlist-test.com';
export const STAGING_US_TLD: Domain = 'www-us.sortlist-test.com';
export const STAGING_BE_TLD: Domain = 'www.sortlist-test.be';

export const PROD_GTLD: Domain = 'www.sortlist.com';
export const PROD_FR_TLD: Domain = 'www.sortlist.fr';
export const PROD_ES_TLD: Domain = 'www.sortlist.es';
export const PROD_NL_TLD: Domain = 'www.sortlist.nl';
export const PROD_DE_TLD: Domain = 'www.sortlist.de';
export const PROD_IT_TLD: Domain = 'www.sortlist.it';
export const PROD_CH_TLD: Domain = 'www.sortlist.ch';
export const PROD_BE_TLD: Domain = 'www.sortlist.be';
export const PROD_CA_TLD: Domain = 'www.sortlist.ca';
export const PROD_US_TLD: Domain = 'www.sortlist.us';
export const PROD_UK_TLD: Domain = 'www.sortlist.co.uk';

// Experiment specific config
export const positiveReviewPathnames: readonly string[] = [
  '/site-internet/france-fr',
  '/web-marketing/france-fr',
  '/identite-visuelle/france-fr',
  '/graphisme/france-fr',
  '/ecommerce/france-fr',
  '/developpement-web/france-fr',
  '/seo/france-fr',
  '/video/france-fr',
  '/social-media/france-fr',
  '/app-mobile/france-fr',
  '/adwords/france-fr',
  '/contenu/france-fr',
  '/pub/france-fr',
  '/ergonomie/france-fr',
  '/evenementiel/france-fr',
  '/s/identite-graphique/france-fr',
].map((pathname) => `${pathname}*`); // allow query params
export const positiveReviewDomains: readonly Domain[] = [LOCALHOST, STAGING_FR_TLD, PROD_FR_TLD];

export const reduceDisplayInformationDomains: readonly Domain[] = [LOCALHOST, STAGING_DE_TLD, PROD_DE_TLD];
export const reduceDisplayInformationExcludedPathnames: readonly string[] = excludedPathnamesForDirectoryExperiments;

export const experienceLevelDomains: readonly Domain[] = [LOCALHOST, STAGING_GTLD, PROD_GTLD];
export const experienceLevelExcludedPathnames: readonly string[] = [
  ...excludedPathnamesForDirectoryExperiments,
  '/**/**', // we don't want paths with 2 or more levels to be included
];
