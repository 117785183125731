// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';

const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENV;
const NODE_ENV = process.env.NODE_ENV;

const ignoreErrors = [
  /**
   * @link https://github.com/WICG/ResizeObserver/issues/38#issuecomment-422126006,
   * @link https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
   */
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'Not found',
  "SyntaxError: Failed to execute 'measure' on 'Performance': The mark 'beforeRender' does not exist.",
  "This page has been deleted and it's not accessible anymore.",
  "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
  'Non-Error exception captured',
];

export function initClient(sentryAppDsn: string, configOverride = {} as Parameters<typeof init>[0]) {
  init({
    dsn: sentryAppDsn,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.005,
    maxBreadcrumbs: 50,
    normalizeDepth: 5,
    attachStacktrace: true,
    ignoreErrors,
    denyUrls: [
      /https:\/\/cdn.segment.com\/.*/,
      /https:\/\/widget.intercom.io\/.*/,
      /https:\/\/www.google-analytics.com\/.*/,
      /https:\/\/www.google.com\/ads\/.*/,
      /https:\/\/js.intercomcdn.com\/.*/,
      /https:\/\/api-iam.intercom.io\/.*/,
      /https:\/\/api.hubapi.com\/.*/,
      /https:\/\/www.facebook.com\/.*/,
      /https:\/\/connect.facebook.net\/.*/,
      /https:\/\/in.hotjar.com\/.*/,
      /https:\/\/www.clarity.ms\/.*/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    //https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/#debug
    debug: NODE_ENV !== 'production',
    beforeSend(event) {
      // When we're developing locally
      if (NODE_ENV !== 'production') {
        // Don't actually send the errors to Sentry
        return null;
      }

      return event;
    },
    environment: SENTRY_ENVIRONMENT as string,
    ...configOverride,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
