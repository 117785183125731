import wildcardMatch from 'wildcard-match';

import { Experiment } from './types';

const isIncluded = (config: string[], test: string): boolean => {
  return config.some((pattern) => wildcardMatch(pattern)(test));
};

export const isRouteActivatedForExperiment = (experiment: Experiment, requestUrl: string) => {
  const url = new URL(requestUrl);
  const { hostname, pathname } = url;

  if (!isIncluded(experiment.domains as string[], hostname)) return false;

  if ('excludedPathnames' in experiment) return !isIncluded(experiment.excludedPathnames as string[], pathname);

  return isIncluded(experiment.pathnames as string[], pathname);
};
