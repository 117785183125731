import { ExperimentControlVariant } from './types';

export const FEATURE_FLAGS_OVERRIDE_PARAM = 'feature-flags';
export const FEATURE_FLAGS_OVERRIDE_ASSIGNATOR = ':';
export const FEATURE_FLAGS_OVERRIDE_SEPARATOR = ',';

export const AB_TESTS_COOKIE_NAME = 'ab-tests';
export const AB_TESTS_COOKIE_ASSIGNATOR = ':';
export const AB_TESTS_COOKIE_SEPARATOR = ',';
export const AB_TESTS_CONTROL: ExperimentControlVariant = 'control';

export const GB_COOKIE_NAME = 'gbuuid';
