import { IncomingMessage } from 'http';
import { HttpBadRequest, HttpGoneError } from '@sortlist-frontend/utils/src/errors';
import { isNonEmptyString } from '@sortlist-frontend/utils/src/typeguards';
import { GetServerSidePropsContext } from 'next';

import { DomainInfo } from '_backend/integration/domain-info';

type ExtractLocaleParams = {
  domainInfo: DomainInfo;
  lang: string | undefined;
  origin: string;
  path: string | undefined;
  skipLocaleCheck: boolean;
};

const extractLocale = (
  params: ExtractLocaleParams,
): {
  locale: string;
  redirectLocale?: boolean;
  redirectNoLocale?: boolean;
} => {
  const { domainInfo, lang, origin, path, skipLocaleCheck } = params;
  const locales = domainInfo.getLocales();

  if (!locales) {
    throw new HttpBadRequest({ message: 'Domain configuration issue!', url: `${origin}${path}` });
  }

  const mainLocale = domainInfo.getMainLocale();
  const pathSteps = path?.split('/');

  const fallback = {
    locale: mainLocale ?? locales[0],
    redirectNoLocale: false,
    redirectLocale: !mainLocale,
  };

  if (pathSteps && pathSteps.length > 1) {
    const locale = pathSteps[1];

    // The first path step is one of the locales we're accepting for
    // this specific domain, then we don't need to redirect, except if
    // it's the mainLocale of that domain, so we just need to remove it
    if (locales?.includes(locale)) {
      return {
        locale,
        redirectLocale: false,
        redirectNoLocale: locale === mainLocale,
      };
    }

    // There is no locale in the path, so we take the one from the
    // query params if exist.
    if (lang) {
      const cleanLang = lang.split('-')[0];

      if (locales?.includes(cleanLang)) {
        return {
          locale: cleanLang,
          redirectLocale: cleanLang !== mainLocale, // if not main, add it to path
          redirectNoLocale: false, // nothing to remove from path
        };
      } else {
        // Temporarily allowing to skip the lang check
        // for pages embedded in the Match App until those are moved
        // https://github.com/sortlist/hello-engineers/issues/325
        if (skipLocaleCheck) return fallback;
        throw new HttpGoneError({
          message: "This page has been deleted and it's not accessible anymore.",
          url: `${origin}${path}`,
        });
      }
    }

    // Nothing in the query params? Just take the mainLocal, and if there
    // isn't one, just take the first accepted locale for that domain.
    return fallback;
  }

  throw new HttpBadRequest({ message: 'Domain configuration issue!', url: `${origin}${path}` });
};

export const extractOrigin = (req: IncomingMessage, defaultHost = 'www.sortlist.local') => {
  let host = req.headers.host || defaultHost;
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http' : 'https';

  const forwardedHost = req.headers['x-forwarded-host'];
  const forwardedProtocol = req.headers['x-forwarded-proto'];

  if (forwardedHost && typeof forwardedHost === 'string') {
    host = forwardedHost;
  }

  if (forwardedProtocol && typeof forwardedProtocol === 'string') {
    protocol = forwardedProtocol;
  }

  protocol = protocol.includes('https') ? 'https' : 'http';

  return protocol + '://' + host;
};

export const extractDomainRelevantInfo = (
  context: GetServerSidePropsContext | null,
  lang: string[] | string | undefined,
  options = { skipLocaleCheck: false },
  originUrl?: string,
  reqPath?: string,
  reqQs?: string,
) => {
  const req = context?.req;
  let origin = originUrl || '';
  const queryLocale = Array.isArray(lang) ? lang[0] : lang;
  if (context) {
    origin = extractOrigin(req as IncomingMessage);
  }
  const domainInfo = DomainInfo.getFromOrigin(origin);
  const path = reqPath || req?.url?.split('?')[0];
  const qs = reqQs || req?.url?.split('?')[1];
  const query = isNonEmptyString(qs) ? `?${qs}` : '';
  const { skipLocaleCheck } = options;

  if (!domainInfo) throw new HttpBadRequest({ message: 'Not an accepted domain' });

  const { locale, redirectLocale, redirectNoLocale } = extractLocale({
    domainInfo,
    lang: queryLocale,
    origin,
    path,
    skipLocaleCheck,
  });

  return {
    // canonical is the current URL without query params.
    // It's used to say to google what is the main page to
    // index and that same page with query params are not
    // separated pages.
    canonical: `${origin}${path === '/' ? '' : path}`,
    // We'll need the query in combination with the canonical
    // to build the alternate links, which are used to indicate
    // to Google what are the exact same pages in other languages.
    // Different parts of the alternate URLs are translated so
    // we'll get them from database and simply add the query.
    query,
    origin,
    locale,
    redirectLocale,
    redirectNoLocale,
    iso31661: domainInfo.getIso31661(),
  };
};
