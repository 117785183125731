import { DeepWriteable } from '@sortlist-frontend/utils';

import { config } from '../defaults';

export enum Locale {
  en = 'en',
  fr = 'fr',
  de = 'de',
  nl = 'nl',
  it = 'it',
  es = 'es',
}

export type Domain =
  | 'www.sortlist.com'
  | 'www.sortlist.fr'
  | 'www.sortlist.de'
  | 'www.sortlist.nl'
  | 'www.sortlist.it'
  | 'www.sortlist.es'
  | 'www.sortlist.co.uk'
  | 'www.sortlist.it'
  | 'www.sortlist.ca'
  | 'www.sortlist.us'
  | 'www.sortlist.ch'
  | 'www.sortlist.be'
  | 'www.sortlist-test.com'
  | 'www-fr.sortlist-test.com'
  | 'www-de.sortlist-test.com'
  | 'www-es.sortlist-test.com'
  | 'www-nl.sortlist-test.com'
  | 'www-it.sortlist-test.com'
  | 'www-ch.sortlist-test.com'
  | 'www-ca.sortlist-test.com'
  | 'www-us.sortlist-test.com'
  | 'www.sortlist-test.be'
  | 'localhost';

export type CurrentExperiment = {
  experiment: keyof typeof config;
  variant: string;
  gbuuid?: string;
};

type RawAvailableFeatureToggles = DeepWriteable<typeof config>;
export type AvailableFeatureNames = keyof RawAvailableFeatureToggles;

export type AvailableFeatureToggles = {
  [Key in AvailableFeatureNames]: RawAvailableFeatureToggles[Key];
};

export type FlagValueString = `${string}-c` | `${string}-${string}`;

export type FeatureFlagValue = FlagValueString | ExperimentControlVariant;

export type ExperimentControlVariant = 'control';

export type Experiment = {
  key: string;
  isRunning?: boolean;
  domains: readonly (Domain | '**')[]; // wildcard patterns for domains
  // This is the gbuuid provided by Growthbook
  assignedUniqueId?: string;
} & (
  | {
      excludedPathnames: readonly string[]; // wildcard patterns for excluded pathnames (blog, contact, robots.txt etc.)
    }
  | {
      pathnames: readonly string[]; // wildcard patterns for pathnames
    }
);

export interface SimpleFeatureFlag {
  defaultValue: FeatureFlagValue | boolean;
}

export interface FeatureFlagWithVariants {
  defaultValue: FeatureFlagValue | boolean;
  variants: readonly FeatureFlagValue[];
}

export interface FeatureFlagWithExperiment extends FeatureFlagWithVariants {
  experiment: Experiment;
}

export type Environment = 'staging' | `sandbox-${number}` | 'sandbox' | 'development';

export interface EnabledForConfig {
  /** If you need to enable the feature flag for production, just set the default value to true */
  enabledForEnvironments?: readonly Environment[];
}

// Only support simple feature flag for now
export type FeatureToggleConfig = (SimpleFeatureFlag | FeatureFlagWithVariants | FeatureFlagWithExperiment) &
  EnabledForConfig;

export type MergedFeatureToggles = Record<AvailableFeatureNames, FeatureToggleConfig>;

export interface FeatureFlagSetup {
  configOverride: Partial<MergedFeatureToggles>;
}

export function isFeatureFlagWithVariants(config: FeatureToggleConfig): config is FeatureFlagWithVariants {
  return 'variants' in config;
}

export function isFeatureFlagWithExperiment(config: FeatureToggleConfig): config is FeatureFlagWithExperiment {
  return 'experiment' in config;
}
