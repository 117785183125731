import { DomainConfig } from './types';

export const sandbox: DomainConfig = {
  pr: {
    iso31661: null,
    main_locale: 'en',
    currency: 'EUR',
    phone_number: '+32 2 808 14 22',
    locales: {
      en: {
        language: 'English',
      },
      nl: {
        language: 'Nederlands',
      },
      fr: {
        language: 'Français',
      },
      es: {
        language: 'Español',
      },
      de: {
        language: 'Deutsch',
      },
    },
    has_blog: true,
    has_dataHub: true,
  },
  be: {
    iso31661: 'BE',
    main_locale: null,
    currency: 'EUR',
    phone_number: '02 808 14 22',
    placeId: 'ChIJl5fz7WR9wUcR8g_mObTy60c',
    icon: '/static/domainInfo/flags/be.svg',
    locales: {
      fr: {
        location: 'Belgique',
        language: 'Français',
        slug: 'belgique-be',
      },
      nl: {
        location: 'België',
        language: 'Nederlands',
        slug: 'belgie-be',
      },
    },
    has_blog: true,
    has_dataHub: true,
    polygon: [
      [
        [6.408124099999999, 51.5051016],
        [6.408124099999999, 49.497013],
        [2.5240999, 49.497013],
        [2.5240999, 51.5051016],
        [6.408124099999999, 51.5051016],
      ],
    ],
  },
  fr: {
    iso31661: 'FR',
    main_locale: 'fr',
    currency: 'EUR',
    phone_number: '07 56 79 82 23',
    placeId: 'ChIJMVd4MymgVA0R99lHx5Y__Ws',
    icon: '/static/domainInfo/flags/fr.svg',
    locales: {
      fr: {
        location: 'France',
        language: 'Français',
        slug: 'france-fr',
      },
    },
    has_blog: true,
    has_dataHub: true,
    polygon: [
      [
        [9.6624999, 51.1241999],
        [9.6624999, 41.31433],
        [-5.5591, 41.31433],
        [-5.5591, 51.1241999],
        [9.6624999, 51.1241999],
      ],
    ],
  },
  nl: {
    iso31661: 'NL',
    main_locale: 'nl',
    currency: 'EUR',
    main_location: 'Nederland',
    phone_number: '+32 2 808 14 22',
    placeId: 'ChIJu-SH28MJxkcRnwq9_851obM',
    icon: '/static/domainInfo/flags/nl.svg',
    locales: {
      nl: {
        location: 'Nederland',
        language: 'Nederlands',
        slug: 'niederlande-nl',
      },
    },
    has_blog: true,
    has_dataHub: true,
    polygon: [
      [
        [7.227140500000001, 53.6756],
        [7.227140500000001, 50.7503837],
        [3.3316, 50.7503837],
        [3.3316, 53.6756],
        [7.227140500000001, 53.6756],
      ],
    ],
  },
  ch: {
    iso31661: 'CH',
    main_locale: 'fr',
    currency: 'CHF',
    placeId: 'ChIJYW1Zb-9kjEcRFXvLDxG1Vlw',
    icon: '/static/domainInfo/flags/ch.svg',
    locales: {
      fr: {
        location: 'Suisse',
        language: 'Français',
        slug: 'suisse-ch',
      },
    },
    polygon: [
      [
        [10.4923401, 47.8084546],
        [10.4923401, 45.81792],
        [5.95608, 45.81792],
        [5.95608, 47.8084546],
        [10.4923401, 47.8084546],
      ],
    ],
  },
  de: {
    iso31661: 'DE',
    main_locale: 'de',
    currency: 'EUR',
    placeId: 'ChIJa76xwh5ymkcRW-WRjmtd6HU',
    icon: '/static/domainInfo/flags/de.svg',
    locales: {
      de: {
        location: 'Deutschland',
        language: 'Deutsch',
        slug: 'deutschland-de',
      },
    },
    has_blog: true,
    has_dataHub: true,
    polygon: [
      [
        [15.0418962, 55.0815],
        [15.0418962, 47.2701115],
        [5.8663425, 47.2701115],
        [5.8663425, 55.0815],
        [15.0418962, 55.0815],
      ],
    ],
  },
  es: {
    iso31661: 'ES',
    main_locale: 'es',
    currency: 'EUR',
    phone_number: '911 98 96 86',
    placeId: 'ChIJi7xhMnjjQgwR7KNoB5Qs7KY',
    icon: '/static/domainInfo/flags/es.svg',
    locales: {
      es: {
        location: 'España',
        language: 'Español',
        slug: 'espana-es',
      },
    },
    has_blog: true,
    has_dataHub: true,
    polygon: [
      [
        [5.098, 45.244],
        [5.098, 35.17300000000001],
        [-12.524, 35.17300000000001],
        [-12.524, 45.244],
        [5.098, 45.244],
      ],
    ],
  },
  it: {
    iso31661: 'IT',
    main_locale: 'it',
    currency: 'EUR',
    placeId: 'ChIJA9KNRIL-1BIRb15jJFz1LOI',
    icon: '/static/domainInfo/flags/it.svg',
    locales: {
      it: {
        location: 'Italia',
        language: 'Italiano',
        slug: 'italia-it',
      },
    },
    polygon: [
      [
        [18.7975999, 47.092],
        [18.7975999, 35.4897],
        [6.6267201, 35.4897],
        [6.6267201, 47.092],
        [18.7975999, 47.092],
      ],
    ],
  },
  us: {
    iso31661: 'US',
    main_locale: 'en',
    currency: 'USD',
    placeId: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw',
    icon: '/static/domainInfo/flags/us.svg',
    locales: {
      en: {
        location: 'United States',
        language: 'English',
        slug: 'united-states-us',
      },
    },
    polygon: [
      [
        [-66.94, 49.38],
        [-66.94, 25.82],
        [-124.39, 25.82],
        [-124.39, 49.38],
        [-66.94, 49.38],
      ],
    ],
  },
  ca: {
    iso31661: 'CA',
    main_locale: 'en',
    currency: 'CAD',
    placeId: 'ChIJ2WrMN9MDDUsRpY9Doiq3aJk',
    icon: '/static/domainInfo/flags/ca.svg',
    locales: {
      en: {
        location: 'Canada',
        language: 'English',
        slug: 'canada-ca',
      },
    },
    polygon: [
      [
        [-50, 70],
        [-50, 42],
        [-142, 42],
        [-142, 70],
        [-50, 70],
      ],
    ],
  },
  gb: {
    iso31661: 'GB',
    main_locale: 'en',
    currency: 'GBP',
    placeId: 'ChIJqZHHQhE7WgIReiWIMkOg-MQ',
    icon: '/static/domainInfo/flags/gb.svg',
    locales: {
      en: {
        location: 'United Kingdom (EN)',
        language: 'English',
        slug: 'united-kingdom-gb',
      },
    },
    has_blog: true,
    has_dataHub: true,
    polygon: [
      [
        [9.5844157, 61.5471111],
        [9.5844157, 47.5554486],
        [-18.5319589, 47.5554486],
        [-18.5319589, 61.5471111],
        [9.5844157, 61.5471111],
      ],
    ],
  },
} as const;
