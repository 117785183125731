import { SSRConfig, UserConfig } from 'next-i18next';
// @ts-expect-error: for some reason the declaration is not found in the `types.d.ts`, ignoring for now
import { serverSideTranslations } from 'next-i18next/dist/esm/serverSideTranslations';

export const getServerSideTranslations = async (
  locale: string,
  namespacesRequired?: string[] | Readonly<string[]> | undefined,
  config?: UserConfig,
): Promise<SSRConfig> => {
  // Slice needed here cause serverSlideTranslations does not accept Readonly type
  return serverSideTranslations(locale, namespacesRequired?.slice(), config);
};
