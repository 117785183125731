import { Domain } from './types';

export const vercel: Domain = {
  iso31661: null,
  main_locale: 'en',
  currency: 'EUR',
  phone_number: '+32 2 808 14 22',
  locales: {
    en: {
      language: 'English',
    },
    nl: {
      language: 'Nederlands',
    },
    fr: {
      language: 'Français',
    },
    es: {
      language: 'Español',
    },
    de: {
      language: 'Deutsch',
    },
    it: {
      language: 'Italiano',
    },
  },
  has_blog: true,
} as const;
